<template>
  <div class="confirmation__summary">
    <h3 class="text-subtitle-1 font-weight-bold mb-3">
      <translate>
        Summary
      </translate>
    </h3>
    <div class="cw-application__table">
      <table>
        <tbody>
          <tr v-for="item in applicationSummary" :key="item.name">
            <td>{{ item.name }}</td>
            <td class="text-right font-weight-medium">
              <template>
                {{ item.value }}
              </template>
            </td>
          </tr>
          <tr v-for="item in withdrawalSummary" :key="item.name">
            <td>{{ item.name }}</td>
            <td class="text-right font-weight-medium">
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwConfirmationSummary',

  computed: {
    ...mapGetters({
      applicationSummary: 'application/getApplicationSummary',
      withdrawalSummary: 'application/getWithdrawalSummary',
    }),
  },
};
</script>
